import React from "react";
import Swiper from "react-id-swiper";
// SCSS
import "swiper/css/swiper.css";
// Components
import { ReviewBox } from "./ReviewBox";

type ReviewsState = {
  reviews: {
    comment: string;
    reviewerName: string;
  }[];
};

const state: ReviewsState = {
  reviews: [
    {
      comment:
        "O parcare pozitionata intr-o zona unde este mare nevoie de un asemenea loc.",
      reviewerName: "George Mandru",
    },
    {
      comment:
        "Brilliant gem 💎\n Thank you very much\n Great parking lot\n I will definitely use it again...",
      reviewerName: "Georgeta Dawes",
    },
    {
      comment: "O parcare în centru, in regulă.",
      reviewerName: "George Hoaghe",
    },
    {
      comment: "Usor de accesat, 10 lei pe ora!",
      reviewerName: "TheSerbster",
    },
    {
      comment: "Good place to park in a almost center of town",
      reviewerName: "V T (Vali)",
    },
    {
      comment:
        "Foarte bună locație de parcare  pentru cei care au de rezolvat probleme in zonă",
      reviewerName: "Petre Savescu",
    },
  ],
};
const MutipleSlidesPerView = () => {
  let reviewsRender;

  if (state.reviews) {
    reviewsRender = state.reviews.map((review, idx) => (
      <div key={idx}>
        <ReviewBox
          comment={review.comment}
          reviewerName={review.reviewerName}
        />
      </div>
    ));
  }

  const params = {
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    breakpoints: {
      1200: {
        slidesPerView: 6,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
  };

  return <Swiper {...params}>{reviewsRender}</Swiper>;
};
export default MutipleSlidesPerView;
