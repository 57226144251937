import React from "react";

import "./aboutBox.scss";

interface AboutBoxProps {
  avatar: string;
  place: string;
  distance: string;
}

const AboutBox = ({ avatar, place, distance }: AboutBoxProps) => (
  <div className="about__box flex-center">
    <img src={avatar} alt="person" />
    <div className="about__box-info">
      <p className="font15 weight800">{place}</p>
      <p className="font12 weight800">{distance}</p>
    </div>
  </div>
);

export default AboutBox;
