import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./about.scss";
// Components
import AboutBox from "./AboutBox";
import AboutInfo from "./AboutInfo";
import { Title } from "../UIComponents";
// Assets
import PiataRomana from "../../assets/about/PiataRomana.webp";
import CaleaVictoriei from "../../assets/about/CaleaVictoriei.webp";

export const About = () => (
  <div id="about">
    <div className="wrapper">
      <Title title="DESPRE NOI." />
      <p className="font14">
        Parcare noastra este amplasata în zona ultracentrală a Bucureștiului și
        oferă parcare privată pentru accesul pietonal rapid la:
        <br /> Piața Romană (2 minute), Piața Amzei (4 minute), Calea Victoriei
        (4 minute), Piața Victoriei (12 minute), Atheneul Român (12 minute),
        Sala Palatului (15 minute), Piața Universității (20 minute).
        <br /> Parcarea este situată într-o zonă ușor accesibilă din chiar inima
        orașului.
      </p>
      <h4
        className="font20 weight800"
        style={{ marginTop: "1.4rem", marginBottom: "1.4rem" }}
      >
        De ce să parcați la noi?
      </h4>
      <p className="font14 weight500">
        - Avem același tarif orar ca și parcările publice,
        <br />- Avem pază umană si monitorizare video,
        <br />- Veți avea acces pietonal foarte rapid la toată zona centrală a
        orașului,
        <br />- Avem personal amabil care vă facilitează accesul și parcarea în
        siguranță,
        <br />- Avem sistem automatizat de acces și de ieșire din parcare.
      </p>
      <Row>
        <Col md={12} lg={4}>
          <AboutBox
            avatar={PiataRomana}
            place="Piața Romană"
            distance="150 m ~ 2 min"
          />
        </Col>
        <Col md={12} lg={4}>
          <AboutBox
            avatar={CaleaVictoriei}
            place="Calea Victoriei"
            distance="250 m ~ 4 min"
          />
        </Col>
        <Col md={12} lg={4}>
          <AboutInfo />
        </Col>
      </Row>
    </div>
  </div>
);
