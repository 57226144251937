import React from "react";
// SCSS
import "./hero.scss";
//Assets
import HeroImageS from "../../assets/hero/hero-image-s.webp";
import HeroImageL from "../../assets/hero/hero-image-l.webp";
import { ReactComponent as DirectionIcon } from "../../assets/hero/directions-icon.svg";
import { ReactComponent as CallIcon } from "../../assets/hero/call-icon.svg";
//Components
import { Button, Title } from "../UIComponents";

export const Hero = () => {
  return (
    <div className="hero">
      <div className="wrapper">
        <div className="hero-info">
          <h1 className="weight800 font30">
            Parcare Piața Romană - Piața Amzei
          </h1>
          <Title title={"Parcare\n Piața Romană\n Piața Amzei"} />
          <div className="hero-buttons">
            <Button
              label="Localizare"
              target="https://www.google.com/maps/dir//Parcare+Piata+Romana+-+Piata+Amzei/data=!4m8!4m7!1m0!1m5!1m1!1s0x40b1ff53d43d86cd:0x6e3a6f0ae47eb699!2m2!1d26.093806999999998!2d44.4466009"
              buttonIcon={<DirectionIcon />}
            />
            <Button
              label="Apelare"
              target="tel:+40-735-152-445"
              buttonIcon={<CallIcon />}
            />
          </div>
        </div>
        <picture className="hero-image">
          <source media="(min-width: 750px)" srcSet={HeroImageL} />
          <img src={HeroImageS} alt="hero" />
        </picture>
      </div>
    </div>
  );
};
