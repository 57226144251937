import { useEffect, useState } from "react";
import "./style/App.scss";
import { DesktopNavBar, MobileNavBar, Backdrop, Footer } from "./layout";
import { Hero, Reviews, About, Contact } from "./components";
function App() {
  const [userIsScrolled, setUserIsScrolled] = useState(false);
  const [mobileNavbarOpen, setMobileNavbarOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      setUserIsScrolled(window.pageYOffset > 0); // Update state based on scroll position
    };

    // Add event listener on mount
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // On closeMobileMenu click close navbar
  const closeMobileMenu = () => {
    setMobileNavbarOpen(false);
  };
  // Mobile menu handler
  const mobileMenuOpen = () => {
    setMobileNavbarOpen(true);
  };

  return (
    <div className="App">
      <MobileNavBar
        isOpen={mobileNavbarOpen}
        closeMobileMenu={closeMobileMenu}
      />
      <Backdrop isOpen={mobileNavbarOpen} closeMobileMenu={closeMobileMenu} />
      <DesktopNavBar
        userIsScrolled={userIsScrolled}
        mobileMenuOpen={mobileMenuOpen}
      />
      <Hero />
      <Reviews />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
