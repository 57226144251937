import React from "react";
import { Col, Row } from "react-flexbox-grid";
// SCSS
import "./reviewBox.scss";
import { ReactComponent as StarIcon } from "../../assets/reviews/star.svg";

interface ReviewBoxProps {
  comment: string;
  reviewerName: string;
}

export const ReviewBox = ({ comment, reviewerName }: ReviewBoxProps) => (
  <div className="review__box flex-center">
    <Col>
      <Row className="review-stars">
        <StarIcon fill="#FFE600" />
        <StarIcon fill="#FFE600" />
        <StarIcon fill="#FFE600" />
        <StarIcon fill="#FFE600" />
        <StarIcon fill="#FFE600" />
      </Row>
      <p className="font13" style={{ marginTop: "0.6rem" }}>
        {comment.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
      <h4 style={{ marginTop: "0.6rem" }}>- {reviewerName} -</h4>
    </Col>
  </div>
);
