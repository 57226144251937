import React from "react";
import { Link } from "react-scroll";
// SCSS
import "./navbar.scss";
// Assets
import MobileMenuIcon from "../../assets/navbar/mobile-menu.svg";
// Components
import { Logo } from "../../components/Logo/Logo";

interface DesktopNavBarProps {
  userIsScrolled: boolean;
  mobileMenuOpen: () => void;
}

export const DesktopNavBar = ({
  userIsScrolled,
  mobileMenuOpen,
}: DesktopNavBarProps) => {
  return (
    <nav className={`Navbar ${!userIsScrolled ? "extraLargeNavbar" : ""}`}>
      <div className="wrapper flex-s-between">
        <div className="logo-container">
          <Link to="hero" spy={true} smooth={true} offset={0} duration={500}>
            <Logo />
          </Link>
        </div>
        <div className="mobile__menu" onClick={mobileMenuOpen}>
          <img src={MobileMenuIcon} alt="menu" />
        </div>
        <div className="desktop__menu">
          <ul className="flex-s-between">
            <li>
              <Link
                activeClass="active-link"
                to="reviews"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                RECENZII
              </Link>
            </li>
            <li>
              <Link
                activeClass="active-link"
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                DESPRE NOI
              </Link>
            </li>
            <li>
              <Link
                activeClass="active-link"
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
