import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./contactInfo.scss";

import { ContactInfoBox } from "./ContactInfoBox";

import LocationIconFilled from "../../assets/contact/location-icon-filled.svg";
import LocationIconEmpty from "../../assets/contact/location-icon-empty.svg";
import PhoneIconFilled from "../../assets/contact/phone-icon-filled.svg";
import PhoneIconEmpty from "../../assets/contact/phone-icon-empty.svg";

export const ContactInfo = () => (
  <Row>
    <Col xs={12}>
      <Row center="xs">
        <Col xs={12} lg={3} className="contact__info">
          <ContactInfoBox
            icon={LocationIconFilled}
            hoverIcon={LocationIconEmpty}
            linkURL="https://www.google.com/maps/dir//Parcare+Piata+Romana+-+Piata+Amzei/data=!4m8!4m7!1m0!1m5!1m1!1s0x40b1ff53d43d86cd:0x6e3a6f0ae47eb699!2m2!1d26.093806999999998!2d44.4466009"
            textLine1="Bulevardul Dacia 17,"
            textLine2="București, România"
          />
        </Col>
        <Col xs={12} lg={3} className="contact__info">
          <ContactInfoBox
            icon={PhoneIconFilled}
            hoverIcon={PhoneIconEmpty}
            linkURL="tel:+40-735-152-445"
            textLine1="+40 735 152 445"
            textLine2=""
          />
        </Col>
      </Row>
    </Col>
  </Row>
);
