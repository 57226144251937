import * as yup from "yup";

export const contactUsValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Numele dvs. este obligatoriu")
    .max(50, "Maxim 50 de caractere permise"),
  email: yup
    .string()
    .lowercase()
    .email("Formatul emailului nu este valid")
    .required("Adresa dvs. de email este obligatorie")
    .trim(),
  message: yup
    .string()
    .required("Mesajul dvs. este obligatoriu")
    .max(500, "Maxim 500 de caractere permise"),
});
