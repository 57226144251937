import React, { useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import emailjs from "@emailjs/browser";
// SCSS
import "./contact.scss";
// Components
import { Title, Modal } from "../UIComponents";
import { ContactInfo } from "./ContactInfo";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactUsValidationSchema } from "./contact.validation";

interface ContactUsForm {
  name: string;
  email: string;
  message: string;
}

export const Contact = () => {
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);

  const closeModal = () => {
    setSuccessModal(false);
    setErrorModal(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isValidating, isSubmitting, errors },
  } = useForm<ContactUsForm>({
    mode: "onChange",
    resolver: yupResolver(contactUsValidationSchema),
  });

  const onSubmit = async (formData: ContactUsForm) => {
    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_YOUR_SERVICE_ID!,
        process.env.REACT_APP_EMAILJS_YOUR_TEMPLATE_ID!,
        { ...formData, reply_to: formData.email } as any,
        {
          publicKey: process.env.REACT_APP_EMAILJS_YOUR_PUBLIC_KEY!,
        }
      );

      reset();
      setSuccessModal(true);

      window.gtag("event", "form_submit", {
        event_category: "contact_us_success",
      });
    } catch (error: any) {
      console.warn(error?.text);
      setErrorModal(true);

      window.gtag("event", "form_submit", {
        event_category: "contact_us_error",
        value: error?.text,
      });
    }
  };

  return (
    <div id="contact">
      {successModal && <Modal closeModal={closeModal} status="success" />}
      {errorModal && <Modal closeModal={closeModal} status="error" />}
      <div className="wrapper">
        <Title title="CONTACTAȚI-NE." />
        <p className="font14">
          Pentru mai multe detalii nu ezitați să ne contactați prin intermediul
          formularului sau direct la numărul de telefon de mai jos.
        </p>

        <Row className="padding40">
          <Col md={12} lg={6}>
            <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
              <h4 className="font30 weight800 padding30">
                Aveți întrebări? Scrieți-ne.
              </h4>
              <input type="text" placeholder="Nume" {...register("name")} />
              {errors.name && (
                <p className="error-text">{errors.name.message}</p>
              )}
              <input type="email" placeholder="Email" {...register("email")} />
              {errors.email && (
                <p className="error-text">{errors.email.message}</p>
              )}
              <textarea
                rows={6}
                cols={50}
                placeholder="Mesaj..."
                {...register("message")}
              ></textarea>
              {errors.message && (
                <p className="error-text">{errors.message.message}</p>
              )}
              <div
                className={
                  "small__button " + (isSubmitting ? "sending-btn" : "")
                }
              >
                {isSubmitting ? (
                  <div className="flex-center">
                    <div className="sbl-circ"></div>
                  </div>
                ) : (
                  <button
                    aria-label="send message"
                    type="submit"
                    value="Send Message"
                    disabled={!isValid || isValidating || isSubmitting}
                  >
                    Trimite Mesajul
                  </button>
                )}
              </div>
            </form>
          </Col>
          <Col md={12} lg={6}>
            <div className="flex-center location-container">
              <iframe
                title="location-map"
                width="570"
                height="470"
                style={{ border: 0 }}
                loading="lazy"
                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJzYY91FP_sUARmbZ-5ApvOm4&key=AIzaSyCdMc2P-_PCq2oSZKWMj5HcQ01UfQSNqas&language=ro"
              ></iframe>
            </div>
          </Col>
        </Row>
        <ContactInfo />
      </div>
    </div>
  );
};
