import React from "react";

import "./navbar.scss";

interface BackdropProps {
  isOpen: boolean;
  closeMobileMenu: () => void;
}

export const Backdrop = ({ isOpen, closeMobileMenu }: BackdropProps) => (
  <div
    onClick={closeMobileMenu}
    className={`backdrop ${isOpen ? "backdrop__open" : ""}`}
  ></div>
);
