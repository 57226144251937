import React from "react";
import Swiper from "./Swiper";
//SCSS
import "./reviews.scss";

export const Reviews = () => (
  <div id="reviews">
    <div className="wrapper">
      <Swiper />
    </div>
  </div>
);
