import React from "react";
// SCSS
import "./aboutInfo.scss";

const AboutInfo = () => (
  <div className="about__info flex-center" id="timetable">
    <div>
      <h4 className="font20 weight800">Program de lucru</h4>
      <div className="grid-container">
        <div className="grid-item border-right">Lun.</div>
        <div className="grid-item">07:00–23:30</div>

        <div className="grid-item border-right">Mar.</div>
        <div className="grid-item">07:00–23:30</div>

        <div className="grid-item border-right">Mie.</div>
        <div className="grid-item">07:00–23:30</div>

        <div className="grid-item border-right">Joi</div>
        <div className="grid-item">07:00–23:30</div>

        <div className="grid-item border-right">Vin.</div>
        <div className="grid-item">07:00–23:30</div>

        <div className="grid-item border-right">Sâm.</div>
        <div className="grid-item">Deschis 24 de ore</div>

        <div className="grid-item border-right">Dum.</div>
        <div className="grid-item">Deschis 24 de ore</div>
      </div>
    </div>
  </div>
);
export default AboutInfo;
