import { Col, Row } from "react-flexbox-grid";
// SCSS
import "./logo.scss";
// Assets
import LogoImg from "../../assets/navbar/logo-parcare-narrow-square.png";

interface LogoProps {
  contrastText?: boolean;
}

export const Logo = ({ contrastText = false }: LogoProps) => {
  return (
    <Row className="Logo" style={{ alignItems: "center" }}>
      <img src={LogoImg} alt="logo" className="pointer" />
      <Col className="logo-text" id={contrastText ? "contrast-logo-text" : ""}>
        <h3 style={{ marginLeft: "4px" }} className="pointer">
          Parcare
        </h3>
        <h3 style={{ marginLeft: "4px" }} className="pointer">
          În Inima
        </h3>
        <h3 style={{ marginLeft: "4px" }} className="pointer">
          Orașului
        </h3>
      </Col>
    </Row>
  );
};
