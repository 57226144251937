import React from "react";
import "./modal.scss";

interface ModalBackdropProps {
  closeModal: () => void;
}

const ModalBackdrop = ({ closeModal }: ModalBackdropProps) => (
  <div
    role="button"
    aria-label="Close"
    tabIndex={0}
    className="backdrop"
    onClick={closeModal}
    onKeyDown={closeModal}
  ></div>
);

export default ModalBackdrop;
