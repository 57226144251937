import React from "react";
import { Link } from "react-scroll";
// SCSS
import "./navbar.scss";
// Assets
import CloseIcons from "../../assets/navbar/mobile-close.svg";
// import Logo from "../../assets/navbar/logo-parcare-narrow-square.png";
// Components
import { Logo } from "../../components/Logo/Logo";

interface MobileNavBarProps {
  isOpen: boolean;
  closeMobileMenu: () => void;
}

export const MobileNavBar = ({
  isOpen,
  closeMobileMenu,
}: MobileNavBarProps) => (
  <div className={`mobile__navbar ${isOpen ? "mobile__open" : ""}`}>
    <div className="mobile__navbar-close" onClick={closeMobileMenu}>
      <img src={CloseIcons} alt="close" />
    </div>
    <div className="mobile__navbar-logo flex-center">
      <Link
        to="hero"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
        onClick={closeMobileMenu}
      >
        <Logo contrastText={true} />
      </Link>
    </div>
    <div className="mobile__navbar-menu">
      <ul>
        <li className="flex-center">
          <Link
            activeClass="active-link"
            to="reviews"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={closeMobileMenu}
          >
            RECENZII
          </Link>
        </li>
        <li className="flex-center">
          <Link
            activeClass="active-link"
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={closeMobileMenu}
          >
            DESPRE NOI
          </Link>
        </li>
        <li className="flex-center">
          <Link
            activeClass="active-link"
            to="timetable"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            onClick={closeMobileMenu}
          >
            PROGRAM DE LUCRU
          </Link>
        </li>
        <li className="flex-center">
          <Link
            activeClass="active-link"
            to="contact"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={closeMobileMenu}
          >
            CONTACT
          </Link>
        </li>
      </ul>
    </div>
  </div>
);
