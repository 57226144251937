import React from "react";

import "./contactInfoBox.scss";

interface ContactInfoBoxProps {
  icon: string;
  hoverIcon: string;
  linkURL: string;
  textLine1: string;
  textLine2: string;
}

export const ContactInfoBox = ({
  icon,
  hoverIcon,
  linkURL,
  textLine1,
  textLine2,
}: ContactInfoBoxProps) => (
  <div className="contact__info-box">
    <div>
      <a href={linkURL} target="_blank" rel="noopener noreferrer">
        <img
          src={icon}
          alt="address"
          onMouseOver={(e): void => {
            hoverIcon && (e.currentTarget.src = hoverIcon);
          }}
          onMouseOut={(e): void => {
            hoverIcon && (e.currentTarget.src = icon || "");
          }}
        />
      </a>
    </div>
    <div>
      <p>{textLine1}</p>
      <p>{textLine2}</p>
    </div>
  </div>
);
