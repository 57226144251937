import { Row } from "react-flexbox-grid";
// SCSS
import "./button.scss";

interface ButtonProps {
  target: string;
  label: string;
  buttonIcon?: JSX.Element;
}

export const Button = ({ target, label, buttonIcon }: ButtonProps) => {
  const handleClick = () => {
    window.gtag("event", "button_click", {
      event_category: `${label}`,
    });

    window.open(target, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="button">
      <button onClick={handleClick} className="button-link">
        <Row style={{ alignItems: "center", justifyContent: "center" }}>
          {buttonIcon}
          <p>{label}</p>
        </Row>
      </button>
    </div>
  );
};
