import React from "react";
import "./modal.scss";

interface ErrorModalProps {
  closeModal: () => void;
}

const ErrorModal = ({ closeModal }: ErrorModalProps) => (
  <div className="error__modal">
    <p>Eroare!</p>
    <p>
      Ceva nu a funcționat.
      <br />
      Vă rugăm să încercați din nou.
    </p>
    <div
      role="button"
      tabIndex={0}
      className="modal__btn flex-center"
      onClick={closeModal}
      onKeyDown={closeModal}
    >
      <p>Ok</p>
    </div>
  </div>
);

export default ErrorModal;
