import React from "react";
import { Row, Col } from "react-flexbox-grid";

import "./title.scss";

interface TitleProps {
  title: string;
}

export const Title = ({ title }: TitleProps) => {
  return (
    <Row>
      <Col className="big__title">
        <h2 className="weight800 font60 padding40">
          {title.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </h2>
      </Col>
    </Row>
  );
};
