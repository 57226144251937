import React from "react";
// @ts-ignore
import Fade from "react-reveal/Fade";
// SCSS
import "./modal.scss";
// Components
import ModalBackdrop from "./ModalBackdrop";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";

interface ModalProps {
  status: "success" | "error";
  closeModal: () => void;
}

export const Modal = ({ status, closeModal }: ModalProps) => {
  let innerModalRender = null;
  if (status === "success") {
    innerModalRender = (
      <Fade bottom duration={500}>
        <SuccessModal closeModal={closeModal} />
      </Fade>
    );
  } else if (status === "error") {
    innerModalRender = (
      <Fade bottom duration={500}>
        <ErrorModal closeModal={closeModal} />
      </Fade>
    );
  }

  return (
    <div className="modal">
      <ModalBackdrop closeModal={closeModal} />
      {innerModalRender}
    </div>
  );
};
