import React from "react";
import "./modal.scss";

interface SuccessModalProps {
  closeModal: () => void;
}

const SuccessModal = ({ closeModal }: SuccessModalProps) => (
  <div className="sucess__modal">
    <p>Succes!</p>
    <p>
      Vă mulțumim pentru mesaj.<br></br>Vă vom răspunde în cel mai scurt timp
      posibil la adresa de e-mail furnizată.
    </p>
    <div
      role="button"
      tabIndex={0}
      className="modal__btn flex-center"
      onClick={closeModal}
      onKeyDown={closeModal}
    >
      <p>Ok</p>
    </div>
  </div>
);

export default SuccessModal;
